import React, { useRef, useState, useEffect } from 'react';

import cn from 'classnames';

import AnchorLink, { AnchorLinkOnClickEvent } from '@helsenorge/designsystem-react/components/AnchorLink';
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon, { IconSize } from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';
import List from '@helsenorge/designsystem-react/components/Icons/List';

import useClickOutside from '@helsenorge/core-utils/click-outside';
import { useHover, useLayoutEvent, usePrevious, useSize } from '@helsenorge/designsystem-react';
import { trackNavigation } from '@helsenorge/framework-utils/adobe-analytics';
import { getSidetittelId } from '@helsenorge/framework-utils/hn-page';

import { Heading } from './getHeadingList';
import { useAnchorClick } from './useAnchorClick';
import { useScrollingHeadingList } from './useScrollingHeadingList';

import styles from './styles.module.scss';

export interface TableOfContentsProps {
  title: string;
  toTheTop: string;
  headingListAriaLabel: string;
  headingList: Heading[];
}

const SCROLL_OFFSET_IN_PX = 8;
const BORDER_HEIGHT_IN_PIXELS = 2;
const DELAY_BEFORE_SCROLLING_MS = 50;
export const DELAY_BEFORE_SCROLLING_ON_LOAD_MS = 250;

const TableOfContents: React.FunctionComponent<TableOfContentsProps> = ({ title, toTheTop, headingListAriaLabel, headingList }) => {
  const tableOfContentsRef = useRef<HTMLDivElement>(null);
  const headingListRef = useRef<HTMLDivElement>(null);
  const [tableOfContentsWidth, setTableOfContentsWidth] = useState<number>();
  const { hoverRef: toggleButtonRef, isHovered: toggleButtonisHovered } = useHover<HTMLButtonElement>();
  const [isOpen, setIsOpen] = useState(true);
  const [heightChanged, setHeightChanged] = useState(false);
  const { currentHeading, scrollToHeading, scrollToElement, isSticky } = useScrollingHeadingList(
    headingList,
    toggleButtonRef,
    tableOfContentsRef
  );
  const previousIsSticky = usePrevious(isSticky);

  const toggleButtonSize = useSize(toggleButtonRef);
  const headingListSize = useSize(headingListRef);

  const scrollToTop = (): void => {
    window.location.href = `#${getSidetittelId()}`;
  };

  const goToHeading = (heading: Heading, immediate = false): void => {
    setIsOpen(false);
    // Når innholdsfortegnelsen skjules, mister den høyde, derfor må vi vente litt
    // før scrollingen faktisk starter slik at vi scroller til riktig sted
    setTimeout(
      () => {
        scrollToHeading(heading, immediate);
      },
      immediate ? DELAY_BEFORE_SCROLLING_ON_LOAD_MS : DELAY_BEFORE_SCROLLING_MS
    );
  };

  const goToElement = (element: HTMLElement, immediate = false): void => {
    setIsOpen(false);
    // Når innholdsfortegnelsen skjules, mister den høyde, derfor må vi vente litt
    // før scrollingen faktisk starter slik at vi scroller til riktig sted
    setTimeout(
      () => {
        scrollToElement(element, immediate);
      },
      immediate ? DELAY_BEFORE_SCROLLING_ON_LOAD_MS : DELAY_BEFORE_SCROLLING_MS
    );
  };

  useAnchorClick(goToElement);

  const handleHeadingClick = (event: AnchorLinkOnClickEvent | undefined, heading: Heading): void => {
    event?.preventDefault();
    setHeightChanged(true);
    goToHeading(heading);
    if (heading.title) {
      trackNavigation('innholdsfortegnelse', heading.title, 'innholdsfortegnelse');
    }
  };

  const handleLayoutChange = (): void => {
    // Innholdsfortegnelsen kan være sticky, og bredden må da settes manuelt. Bruker bredden på parentElement for dette.
    const parentElementWidth = tableOfContentsRef?.current?.parentElement?.getBoundingClientRect()?.width;
    setTableOfContentsWidth(parentElementWidth);
  };

  useEffect(() => {
    const id = decodeURI(window.location.hash).substring(1);
    const initialHeading = headingList.find(({ slug }) => slug === id);
    const element = document.getElementById(id);
    if (initialHeading) {
      goToHeading(initialHeading, true);
    } else if (element) {
      goToElement(element, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // previousIsSticky og isSticky kan være undefined,
    // derfor en ekstra sjekk på at innholdsfortegnelsen
    // faktisk har byttet fra sticky til ikke-sticky
    if (previousIsSticky === true && !isSticky) {
      setIsOpen(true);
    } else if (!previousIsSticky && isSticky === true) {
      setIsOpen(false);
    }
    setHeightChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSticky]);

  // Høyden på innholdsfortegnelsen må settes manuelt slik at skygge/border blir riktig
  const tableOfContentsHeight = (toggleButtonSize?.height ?? 0) + (headingListSize?.height ?? 0) + BORDER_HEIGHT_IN_PIXELS;

  // Når innholdsfortegnelsen er sticky, mister den høyden sin. For at scrolling skal oppføre seg likt både som sticky
  // og ikke-sticky, må vi sette høyden på parent-elementet manuelt slik at "noe" tar opp plassen.
  // For å unngå hopping settes høyden på parent-elementet kun når høyden endres og er større
  if (tableOfContentsRef.current?.parentElement) {
    if ((!isSticky && tableOfContentsRef.current.parentElement.getBoundingClientRect().height < tableOfContentsHeight) || heightChanged) {
      tableOfContentsRef.current.parentElement.style.height = `${tableOfContentsHeight}px`;
    }
  }

  // Legg på scroll-padding-top når TOC er sticky for at elementer man fokuserer på ved keyboardnavigasjon skal scrolles helt inn i viewport
  useEffect(() => {
    if (isSticky) {
      document.documentElement.style.scrollPaddingTop = `${tableOfContentsHeight + SCROLL_OFFSET_IN_PX}px`;
    } else {
      document.documentElement.style.scrollPaddingTop = '';
    }
  }, [isSticky, tableOfContentsHeight]);

  useLayoutEvent(handleLayoutChange);
  useClickOutside(tableOfContentsRef, () => isSticky && setIsOpen(false));

  const onToggleClick = (): void => {
    setHeightChanged(true);
    setIsOpen(!isOpen);
  };

  const toggleClassName = cn(
    styles.tableofcontents__toggle,
    isOpen && styles['tableofcontents__toggle--open'],
    isSticky && styles['tableofcontents__toggle--sticky']
  );

  const listClassName = cn(
    styles.tableofcontents__list,
    isOpen && styles['tableofcontents__list--open'],
    isSticky && styles['tableofcontents__list--sticky']
  );

  return (
    <nav
      className={cn(styles.tableofcontents, isSticky && styles['tableofcontents--sticky'])}
      ref={tableOfContentsRef}
      style={{ width: tableOfContentsWidth, height: tableOfContentsHeight }}
      aria-label={headingListAriaLabel}
    >
      <button className={toggleClassName} onClick={onToggleClick} ref={toggleButtonRef} aria-expanded={isOpen}>
        <Icon svgIcon={List} isHovered={toggleButtonisHovered} size={IconSize.XSmall} />
        <span className={styles['tableofcontents__title']}>{title}</span>
        {isSticky && (
          <Icon
            svgIcon={isOpen ? ChevronUp : ChevronDown}
            isHovered={toggleButtonisHovered}
            size={IconSize.XSmall}
            className={styles['tableofcontents__expanded-icon']}
          />
        )}
      </button>
      <div className={listClassName} ref={headingListRef}>
        {isSticky && (
          <Button onClick={scrollToTop} variant="borderless">
            {toTheTop} <Icon svgIcon={ChevronUp} />
          </Button>
        )}
        {headingList.map((heading, index) => (
          <AnchorLink
            key={index}
            id={heading.optionId}
            className={cn(currentHeading === heading && styles['tableofcontents__link--active'])}
            onClick={(event): void => handleHeadingClick(event, heading)}
            href={`#${heading.slug}`}
          >
            {heading.title}
          </AnchorLink>
        ))}
      </div>
    </nav>
  );
};

export default TableOfContents;
