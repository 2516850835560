export var VimeoSubtitleLanguageCodes;
(function (VimeoSubtitleLanguageCodes) {
    VimeoSubtitleLanguageCodes["NORWEGIAN"] = "nb";
    VimeoSubtitleLanguageCodes["NORWEGIAN_NYNORSK"] = "nn";
    VimeoSubtitleLanguageCodes["ENGLISH"] = "en-GB";
    VimeoSubtitleLanguageCodes["LITHUANIAN"] = "lt";
    VimeoSubtitleLanguageCodes["POLISH"] = "pl";
    VimeoSubtitleLanguageCodes["RUSSIAN"] = "ru";
    VimeoSubtitleLanguageCodes["ROMANIAN"] = "ro";
    VimeoSubtitleLanguageCodes["SAMI_NORTHERN"] = "se";
})(VimeoSubtitleLanguageCodes || (VimeoSubtitleLanguageCodes = {}));
