import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { VideoType } from '../types/entities';
import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';
import { hasCookie } from '@helsenorge/framework-utils/cookie';
import { setSharedCookie } from '@helsenorge/framework-utils/shared-cookies';
import { VimeoSubtitleLanguageCodes } from './constants';
import YouTubeTermsOfService from './youtube-terms-of-service';
import cmsstyles from './styles.module.scss';
/**
 * Navn på cookien som brukes til å huske at brukeren har akseptert YouTubes vilkår
 */
const YOUTUBE_TOS_COOKIE_NAME = 'hn-youtube-tos-accepted';
/**
 * Navn på event som brukes til å skjule vilkår fra alle YouTube-videoer samtidig.
 */
const YOUTUBE_EVENT_NAME = 'hn-youtube-settosaccepted';
/**
 * Hvor mange dager før brukeren på nytt må ta stilling til YouTubes vilkår
 */
const YOUTUBE_COOKIE_AGE_DAYS = 365;
const VideoSpiller = props => {
    const { title, description, videoId, videoType, imageUrl, altText, showTextContent = false, resources, subtitleLanguageCode = VimeoSubtitleLanguageCodes.NORWEGIAN, className, } = props;
    const [tosAccepted, setTosAccepted] = useState(false);
    const handleAccept = (remember) => {
        setTosAccepted(true);
        document.dispatchEvent(new CustomEvent(YOUTUBE_EVENT_NAME));
        if (remember) {
            setSharedCookie(YOUTUBE_TOS_COOKIE_NAME, '1', YOUTUBE_COOKIE_AGE_DAYS);
        }
    };
    const handleAcceptEvent = () => {
        setTosAccepted(true);
    };
    useEffect(() => {
        setTosAccepted(hasCookie(YOUTUBE_TOS_COOKIE_NAME));
        document.addEventListener(YOUTUBE_EVENT_NAME, handleAcceptEvent);
        return () => {
            document.removeEventListener(YOUTUBE_EVENT_NAME, handleAcceptEvent);
        };
    }, []);
    const getVideoUrl = () => {
        if (videoType === VideoType.youtube) {
            return `https://www.youtube-nocookie.com/embed/${videoId}?&rel=0`;
        }
        else if (videoType === VideoType.vimeo) {
            return `https://player.vimeo.com/video/${videoId}?texttrack=${subtitleLanguageCode}`;
        }
        else if (videoType === VideoType.vimeoShowcase) {
            return `https://vimeo.com/showcase/${videoId}/embed?texttrack=${subtitleLanguageCode}`;
        }
        return '';
    };
    const getAllowAttribute = () => {
        if (videoType === VideoType.youtube) {
            return 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
        }
        else if (videoType === VideoType.vimeo) {
            return 'fullscreen;';
        }
        else if (videoType === VideoType.vimeoShowcase) {
            return 'fullscreen;';
        }
        return '';
    };
    const videoUrl = getVideoUrl();
    const allowAttribute = getAllowAttribute();
    const showTermsOfService = videoType === VideoType.youtube && !tosAccepted && resources;
    if (!allowAttribute || !videoUrl) {
        return null;
    }
    const renderTextContent = () => (_jsxs("div", { className: cmsstyles.videobeskrivelse, children: [title && _jsx("h3", { className: classNames(designsystemtypography.title3, cmsstyles.videobeskrivelse__title), children: title }), description && _jsx("p", { className: cmsstyles.videobeskrivelse__description, children: description })] }));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames(cmsstyles.videospiller, cmsstyles['videospiller--16by9'], className), children: [showTermsOfService && (_jsx(YouTubeTermsOfService, { title: title || altText, imageUrl: imageUrl, resources: resources, handleAccept: handleAccept })), _jsx("iframe", { src: showTermsOfService ? '' : videoUrl, className: cmsstyles.videospiller__iframe, allow: allowAttribute, title: altText || title })] }), showTextContent && renderTextContent()] }));
};
VideoSpiller.displayName = 'VideoSpiller';
export default VideoSpiller;
