import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import classNames from 'classnames';
import { CardsPerRow, VideoTheme } from '../types/entities';
import Title from '@helsenorge/designsystem-react/components/Title';
import VideoKort from '../videokort';
import VideoSpiller from './../videospiller';
import { VimeoSubtitleLanguageCodes } from '../videospiller/constants';
import styles from './styles.module.scss';
const VideoMatrise = ({ title, videoCards, cardsPerRow = CardsPerRow.four, theme = VideoTheme.cherry, hasFullWidth = false, resources, subtitleLanguageCode = VimeoSubtitleLanguageCodes.NORWEGIAN, }) => {
    const [currentIndex, setIndex] = React.useState();
    const matriseRef = useRef(null);
    React.useEffect(() => {
        const scrollToVideo = () => {
            if (typeof currentIndex !== 'undefined' && matriseRef.current) {
                matriseRef.current.scrollIntoView();
            }
        };
        scrollToVideo();
    }, [currentIndex]);
    if (videoCards.length === 0) {
        return null;
    }
    return (_jsxs("div", { ref: matriseRef, children: [title && (_jsx(Title, { margin: 1.5, htmlMarkup: "h2", appearance: "title3", children: title })), _jsx("div", { className: "row", children: videoCards.map((card, index) => {
                    const wrapperClasses = classNames('col-12 col-md-6', {
                        ['col-lg-3']: cardsPerRow === CardsPerRow.four,
                        ['col-lg-4']: cardsPerRow === CardsPerRow.three,
                        ['col-lg-6']: cardsPerRow === CardsPerRow.two,
                        ['col-lg-12']: cardsPerRow === CardsPerRow.one,
                    });
                    return (_jsxs(React.Fragment, { children: [_jsx(VideoKort, { title: card.title, description: card.description, imageUrl: card.imageUrl, onClick: () => setIndex(index), wrapperClassName: wrapperClasses, cardsPerRow: cardsPerRow, selected: currentIndex === index, theme: theme, hasFullWidth: hasFullWidth, playVideoLabel: resources?.playVideo, testId: 'videoKort' }), currentIndex === index && (_jsx(VideoSpiller, { videoId: card.videoId, videoType: card.videoType, imageUrl: card.imageUrl, title: card.title, altText: card.altText, resources: resources, className: styles['videospiller--matrise'], subtitleLanguageCode: subtitleLanguageCode }))] }, index));
                }) })] }));
};
export default VideoMatrise;
